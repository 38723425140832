import { render, staticRenderFns } from "./KnowledgeUpdate.vue?vue&type=template&id=0fa7361c&scoped=true"
import script from "./KnowledgeUpdate.vue?vue&type=script&lang=js"
export * from "./KnowledgeUpdate.vue?vue&type=script&lang=js"
import style0 from "./KnowledgeUpdate.vue?vue&type=style&index=0&id=0fa7361c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0fa7361c",
  null
  
)

export default component.exports